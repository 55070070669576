.main {

    .bottom-tips {
        max-width: 100vw;
        width: 100%;

        z-index: 2;
        position: fixed;
        bottom: 20px;

        display: flex;
        align-items: center;
        justify-content: center;

        pointer-events: none;

        transition: 0.2s;

        .bottom-tips-qr {
            position: fixed;
            left: 1.5%;
            bottom: 20px;

            img {
                width: 100px;
            }
        }

        .bottom-tips-marks {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .bottom-tips-mark {

                display: flex;
                align-items: center;
                // justify-content: space-between;

                .bottom-tips-mark-image {
                    margin-right: 5px;
                    height: 20px;

                    img {
                        width: 20px;

                        border-radius: 100px;
                        box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.05);
                    }
                }
                
                .bottom-tips-mark-label {
                    margin-right: 15px;

                    font-size: 13px;
                    font-weight: 700;

                    color: #FFFFFF;
                }
            }
        }

        .bottom-tips-maptype {
            width: 55px;
            height: 55px;

            position: fixed;
            left: 60px;
            top: 170px;

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            box-sizing: border-box;
            border: 1px solid #FFFFFF;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);

            cursor: pointer;
            pointer-events: all;

            transition: 0.1s;
        }
    }

}
@media (max-width: 600px) and (orientation: portrait) {
    .main {

        .bottom-tips {
            max-width: 100vw;
            width: 100%;
    
            z-index: 99;
            position: fixed;
            bottom: 20px;
    
            display: flex;
            align-items: center;
            justify-content: center;
    
            pointer-events: none;
    
            transition: 0.2s;
    
            .bottom-tips-qr {
                position: fixed;
                left: 1.5%;
                bottom: 20px;
    
                img {
                    width: 100px;
                }
            }
    
            .bottom-tips-marks {
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                .bottom-tips-mark {
    
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
    
                    .bottom-tips-mark-image {
                        margin-right: 5px;
                        height: 20px;
    
                        img {
                            width: 20px;
    
                            border-radius: 100px;
                            box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.05);
                        }
                    }
                    
                    .bottom-tips-mark-label {
                        margin-right: 15px;
    
                        font-size: 13px;
                        font-weight: 700;
    
                        color: #FFFFFF;
                    }
                }
            }
    
            .bottom-tips-maptype {
                width: 55px;
                height: 55px;
    
                position: fixed;
                left: 30px !important;
                top: 140px !important;
    
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
    
                box-sizing: border-box;
                border: 1px solid #FFFFFF;
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    
                cursor: pointer;
                pointer-events: all;
    
                transition: 0.1s;
            }
        }
    
    }
}
@media (max-width: 1100px) and (orientation: landscape) {
    .main {

        .bottom-tips {
            max-width: 100vw;
            width: 100%;
    
            z-index: 99;
            position: fixed;
            bottom: 20px;
    
            display: flex;
            align-items: center;
            justify-content: center;
    
            pointer-events: none;
    
            transition: 0.2s;
    
            .bottom-tips-qr {
                position: fixed;
                left: 1.5%;
                bottom: 20px;
    
                img {
                    width: 100px;
                }
            }
    
            .bottom-tips-marks {
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                .bottom-tips-mark {
    
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
    
                    .bottom-tips-mark-image {
                        margin-right: 5px;
                        height: 20px;
    
                        img {
                            width: 20px;
    
                            border-radius: 100px;
                            box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.05);
                        }
                    }
                    
                    .bottom-tips-mark-label {
                        margin-right: 15px;
    
                        font-size: 13px;
                        font-weight: 700;
    
                        color: #FFFFFF;
                    }
                }
            }
    
            .bottom-tips-maptype {
                width: 55px;
                height: 55px;
    
                position: fixed;
                left: 30px !important;
                top: 70% !important;
    
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
    
                box-sizing: border-box;
                border: 1px solid #FFFFFF;
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    
                cursor: pointer;
                pointer-events: all;
    
                transition: 0.1s;
            }
        }
    
    }
}
@media (max-width: 820px) and (orientation: portrait) {
    .main {

        .bottom-tips {
            max-width: 100vw;
            width: 100%;
    
            z-index: 99;
            position: fixed;
            bottom: 20px;
    
            display: flex;
            align-items: center;
            justify-content: center;
    
            pointer-events: none;
    
            transition: 0.2s;
    
            .bottom-tips-qr {
                position: fixed;
                left: 1.5%;
                bottom: 20px;
    
                img {
                    width: 100px;
                }
            }
    
            .bottom-tips-marks {
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                .bottom-tips-mark {
    
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
    
                    .bottom-tips-mark-image {
                        margin-right: 5px;
                        height: 20px;
    
                        img {
                            width: 20px;
    
                            border-radius: 100px;
                            box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.05);
                        }
                    }
                    
                    .bottom-tips-mark-label {
                        margin-right: 15px;
    
                        font-size: 13px;
                        font-weight: 700;
    
                        color: #FFFFFF;
                    }
                }
            }
    
            .bottom-tips-maptype {
                width: 55px;
                height: 55px;
    
                position: fixed;
                left: 30px !important;
                top: 145px !important;
    
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
    
                box-sizing: border-box;
                border: 1px solid #FFFFFF;
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    
                cursor: pointer;
                pointer-events: all;
    
                transition: 0.1s;
            }
        }
    
    }
}