@media screen and (max-width: 500px) {
    .start-city-selection {
        box-sizing: border-box;
        padding-top: 100px;

        .logo {
            width: 100px;
            height: auto;
        }

        .start-city-selection-separator {
            transform: translate(-50%, -50%) rotate(90deg) !important;

            top: calc(50%);
            transform: translate(-50%, -50%);

            height: 100vw;
            width: 5px;
        }

        .half-screen {
            flex-direction: column;
        }
    }
}

@media screen and (max-height: 500px) {
    .start-city-selection {
        box-sizing: border-box;
        padding-top: 30px;

        .logo {
            width: 100px;
            height: auto;
        }

        .start-city-selection-separator {
            top: calc(50% + 20px);
            transform: translate(-50%, -50%);

            height: 50vh;
            width: 5px;
        }
    }
}
