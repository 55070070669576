// @media (max-width: 4096px) {
//     .main {
//         .header {
//             width: 100%;

//             z-index: 3;
//             position: fixed;
//             top: 0;

//             pointer-events: none;
//             user-select: none;

//             transition: 0.2s;

//             .header-logo-button {
//                 .arrow-back-button {
//                     // border: none;
//                     background-color: #f2f1ed;
//                     // width: 60px;
//                     height: 27px;
//                     border-radius: 40px;
//                     padding: 8px 16px;
//                     gap: 14px;

//                     position: fixed;
//                     top: 115px;
//                     left: 40px;

//                     cursor: pointer;
//                     text-decoration: none;

//                     display: flex;
//                     align-items: center;
//                     justify-content: center;

//                     border: 1px solid #999999;

//                     p {
//                         line-height: 14px;
//                         font-size: 13px;
//                         font-weight: 400;
//                         font-family: stolzl, sans-serif;
//                         color: #111;
//                     }
//                 }
//                 .header-filter-container {
//                     display: none;
//                 }
//                 .header-container-img {
//                     display: none;
//                 }
//             }
//             .header-filter-elements {
//                 display: none;
//             }

//             .header-logo {
//                 max-width: 100px;
//                 width: 100%;

//                 cursor: pointer;

//                 position: fixed;
//                 top: 15px;
//                 left: 40px;

//                 .header-logo-top {
//                     // margin-bottom: 6px;

//                     img {
//                         height: 85px;
//                         pointer-events: all;
//                     }
//                 }

//                 .header-logo-bottom {
//                     font-size: 13px;
//                     font-weight: 700;
//                     text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);

//                     color: #ffffff;

//                     pointer-events: all;
//                 }
//             }

//             .header-container {
//                 max-width: 97%;
//                 width: 100%;

//                 margin: 0 auto;
//                 margin-top: 28px;

//                 display: flex;
//                 align-items: center;
//                 justify-content: end;

//                 .header-panel {
//                     display: flex;
//                     justify-content: center;
//                     max-width: 1000px;
//                     width: 100%;

//                     .header-filter-elements {
//                         display: flex;
//                         margin: 7px 20px 0 0;
//                         z-index: 99;
//                         max-width: 100%;
//                         .header-filter-section {
//                             display: flex;
//                             .header-filter-button {
//                                 cursor: pointer;
//                                 width: 66px;
//                                 height: 54px;
//                                 margin: 0 12px;
//                                 &:hover img {
//                                     background-color: #3c373a;
//                                     border-radius: 8px;
//                                 }
//                             }
//                         }
//                     }
//                     .header-container-img {
//                         position: absolute;
//                         top: 0;
//                         right: 200px;

//                         pointer-events: none !important;
//                     }
//                     .header-select {
//                         display: flex;
//                         max-width: 220px;
//                         width: 100%;
//                         margin: 10px 0;
//                         .header-project-type-select {
//                             max-width: 220px;
//                             width: 100%;
//                             .header-dropdown-button {
//                                 border: solid 1px #e0dee3;
//                                 border-radius: 40px;
//                                 height: 40px;
//                                 padding: 0 16px;

//                                 background-color: #fff;

//                                 display: flex;
//                                 align-items: center;
//                                 justify-content: space-between;

//                                 position: relative;

//                                 transition: 0.2s;

//                                 cursor: pointer;
//                                 position: relative;
//                                 box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

//                                 .header-dropdown-button-text {
//                                     color: #3c373a;
//                                     font-family: stolzl, sans-serif;
//                                     font-size: 12px;
//                                     font-weight: 400;
//                                     line-height: 14px;

//                                     margin-right: 85px;

//                                     b {
//                                         color: #ffffff;
//                                     }
//                                 }

//                                 img {
//                                     width: 10px;
//                                     height: 21px;

//                                     transition: 0.2s;
//                                 }
//                             }
//                         }
//                         .header-panel-bottom {
//                             pointer-events: all;
//                             position: absolute;
//                             justify-content: center;
//                             display: flex;
//                             max-width: 214px;
//                             width: 100%;

//                             .header-dropdown-list {
//                                 position: absolute;
//                                 z-index: -99;

//                                 display: flex;
//                                 padding-top: 20px;

//                                 max-width: 216px;
//                                 width: 100%;

//                                 background-color: rgba(0, 0, 0, 0.2);
//                                 border-radius: 0 0 8px 8px;

//                                 border: 1px solid #e0dee3;

//                                 transition: 0.25s ease-out;

//                                 box-sizing: border-box;

//                                 transform-origin: top;

//                                 .header-dropdown-list-element {
//                                     width: 100%;
//                                     height: 14px;

//                                     display: flex;
//                                     justify-content: center;

//                                     cursor: pointer;
//                                     transition: 0.2s;

//                                     color: #fff;
//                                     font-family: Stolzl, sans-serif;
//                                     font-size: 14px;
//                                     font-weight: 400;
//                                     line-height: 14px;

//                                     margin: 26px 11px;

//                                     transform-origin: top;

//                                     &:last-child {
//                                         .header-dropdown-list-element-inner {
//                                             border: 0;
//                                         }
//                                     }

//                                     .header-dropdown-list-element-inner {
//                                         width: 100%;
//                                         display: flex;
//                                         flex-direction: column;
//                                         align-items: flex-start;

//                                         .header-dropdown-list-element-label {
//                                             display: flex;
//                                             align-items: center;
//                                             cursor: pointer;
//                                             margin-bottom: 10px;
//                                         }

//                                         .header-dropdown-list-element-input-checkbox {
//                                             display: none;
//                                         }

//                                         .custom-checkbox {
//                                             width: 16px;
//                                             height: 16px;
//                                             position: relative;
//                                             margin-right: 10px;
//                                         }

//                                         .custom-checkbox::before,
//                                         .custom-checkbox::after {
//                                             content: "";
//                                             position: absolute;
//                                             border-radius: 1px;
//                                         }

//                                         .custom-checkbox::before {
//                                             width: 17px;
//                                             height: 17px;
//                                             border: 1px solid #fff;
//                                         }

//                                         .custom-checkbox::after {
//                                             top: 0px;
//                                             left: 0px;
//                                             width: 17px;
//                                             height: 17px;
//                                             border: 1px solid #fff;
//                                         }

//                                         .header-dropdown-list-element-input-checkbox:checked
//                                             + .custom-checkbox::before {
//                                             background-color: #fff;
//                                             width: 9px;
//                                             height: 9px;
//                                             top: 4px;
//                                             left: 4px;
//                                         }

//                                         .header-dropdown-list-element-text {
//                                             color: #fff;
//                                             font-size: 14px;
//                                         }

//                                         .header-dropdown-list-checkbox-button {
//                                             width: 16px;
//                                             height: 16px;

//                                             // border: 1px solid rgb(63, 63, 63);
//                                             border: 1px solid rgb(255, 255, 255);
//                                             border-radius: 1px;
//                                             box-sizing: border-box;

//                                             display: flex;
//                                             align-items: center;
//                                             justify-content: center;

//                                             .header-dropdown-list-checkbox-button-inner {
//                                                 width: 10px;
//                                                 height: 10px;

//                                                 // background-color: #a15652;
//                                                 background-color: rgba(
//                                                     77,
//                                                     172,
//                                                     137,
//                                                     1
//                                                 );
//                                                 border-radius: 1px;

//                                                 transition: 0.2s;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }

//                             .header-guide {
//                                 position: fixed;
//                                 z-index: 1;

//                                 background: #fff;
//                                 box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

//                                 // height: 164px;
//                                 max-width: 377px;
//                                 width: 100%;

//                                 transition: 0.25s;
//                                 transform-origin: top;

//                                 padding: 24px 0 24px 0;
//                                 box-sizing: border-box;

//                                 display: flex;
//                                 align-items: center;
//                                 flex-direction: column;

//                                 .header-guide-element {
//                                     max-width: calc(100% - 32px);
//                                     width: 100%;

//                                     margin-bottom: 20px;

//                                     display: flex;
//                                     align-items: center;

//                                     &:last-child {
//                                         margin-bottom: 0;
//                                     }

//                                     .header-guide-element-img {
//                                         min-width: 48px;
//                                         height: 48px;

//                                         background-color: #d1eeea;
//                                         border-radius: 150px;

//                                         display: flex;
//                                         align-items: center;
//                                         justify-content: center;

//                                         margin-right: 16px;

//                                         img {
//                                             height: 24px;
//                                         }
//                                     }

//                                     .header-guide-element-label {
//                                         color: #1c1e27;
//                                         font-family: Graphik Arabic;
//                                         font-size: 14px;
//                                         font-weight: 400;
//                                     }
//                                 }

//                                 .header-guide-button {
//                                     width: 100%;
//                                     max-width: 140px;
//                                     height: 38px;
//                                     background-color: #00b087;

//                                     display: flex;
//                                     align-items: center;
//                                     justify-content: center;

//                                     cursor: pointer;

//                                     transition: 0.2s;

//                                     .header-guide-button-text {
//                                         color: #ffffff;
//                                         font-family: Graphik Arabic;
//                                         font-size: 11px;
//                                         font-weight: 400;

//                                         letter-spacing: 1px;
//                                         text-transform: uppercase;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     .show-options {
//                         margin: 15px 0;
//                         .show-option {
//                             .show-option-label {
//                                 display: flex;

//                                 .header-switch-element {
//                                     margin: 0 12px 4px 28px;
//                                 }

//                                 span {
//                                     font-size: 12px;
//                                     line-height: 14px;
//                                     font-weight: 400;
//                                     font-family: stolzl, sans-serif;
//                                     width: 100px;
//                                     height: 14px;
//                                     color: #3c373a;
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 .header-panel-mobile {
//                     display: none;
//                 }
//                 .header-panel-mobile-rotated {
//                     display: none;
//                 }
//             }
//         }
//     }
// }
@media (max-width: 820px) and (orientation: portrait) {
    .main {
        .header {
            .header-logo-button {
                .header-logo {
                    position: relative;
                    z-index: 99;
                    .header-logo-top {
                        margin-top: 0px;
                        margin-left: -25px;
                        img {
                            width: 80px !important;
                            height: auto !important;
                        }
                    }
                }
                .arrow-back-button {
                    border: none;
                    z-index: 99;
                    background-color: #f2f1ed;
                    width: auto;
                    height: auto;
                    border-radius: 40px;
                    padding: 1px 16px;
                    gap: 6px;

                    position: fixed;
                    top: 8vh;
                    left: 15px;

                    cursor: pointer;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    border: 1px solid #999999;

                    p {
                        line-height: 14px;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: stolzl, sans-serif;
                        color: #111;
                    }
                }

                .header-filter-container {
                    position: absolute;
                    z-index: 99;
                    top: 150px;
                    left: 15px;
                    width: auto;
                    max-height: 100%;
                    overflow: hidden;
                    display: block;
                    transition: max-height 0.3s ease-in-out,
                        opacity 0.3s ease-in-out;

                    &.collapsed {
                        max-height: auto;
                    }

                    &.expanded {
                        max-height: 57vh;
                        opacity: 1;
                    }

                    .header-filter-elements {
                        display: block;
                        justify-content: flex-start;
                        align-items: center;
                        opacity: 1;
                        transition: opacity 0.3s ease-in-out;
                    }

                    .header-filter-button.close-button {
                        margin-top: 1%;
                    }

                    &.collapsed .header-filter-elements {
                        opacity: 0;
                        pointer-events: none;
                    }

                    .header-filter-button {
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        img {
                            width: 66px;
                            height: 66px;
                        }
                    }
                }

                .header-container-img {
                    display: block;
                    position: absolute;
                    top: -20px;
                    left: 0;
                }
            }
            // .header-filter-container {
            //     position: absolute;
            //     z-index: 99;
            //     top: 150px;
            //     left: 15px;
            //     width: auto;
            //     max-height: 100%;
            //     overflow: hidden;
            //     display: block;
            //     transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
            //     opacity: 1;

            //     &.collapsed {
            //         max-height: 10vh;
            //     }

            //     &.expanded {
            //         max-height: 70vh;
            //         opacity: 1;
            //     }

            //     .header-filter-elements {
            //         display: block;
            //         justify-content: flex-start;
            //         align-items: center;
            //         opacity: 1;
            //         transition: opacity 0.3s ease-in-out;
            //     }

            //     .header-filter-button.close-button {
            //         margin-top: 1%;
            //     }

            //     &.collapsed .header-filter-elements {
            //         opacity: 0;
            //         pointer-events: none;
            //     }

            //     .header-filter-button {
            //         cursor: pointer;
            //         display: flex;
            //         justify-content: center;
            //         align-items: center;
            //         padding: 10px;
            //         margin: 5% 0;
            //         img {
            //             width: 66px;
            //             height: 66px;
            //         }
            //     }
            // }
            .header-filter-elements {
                top: 205px;
                left: 25px;
                position: absolute;
                z-index: 99;
                display: block;
                justify-content: flex-start;
                align-items: center;
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
                .header-filter-section {
                    .header-filter-button {
                        margin: 8px 0;
                    }
                }
            }

            .header-container {
                max-width: 97%;
                width: 100%;

                margin: 0 auto;
                margin-top: 28px;

                display: flex;
                align-items: center;
                justify-content: end;

                .header-panel {
                    display: none;
                }
                .header-panel-mobile {
                    display: block;
                    .header-logo {
                        .header-logo-top {
                            margin-top: -53px;
                            img {
                                width: 80px !important;
                                height: auto !important;
                            }
                        }
                    }
                    .header-select {
                        display: flex;
                        max-width: 220px;
                        width: 100%;
                        margin-top: 10px;
                        .header-project-type-select {
                            max-width: 220px;
                            width: 100%;
                            z-index: 100;
                            .header-dropdown-button {
                                border: solid 1px #e0dee3;
                                border-radius: 40px;
                                height: 40px;
                                padding: 0 16px;

                                background-color: #fff;

                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                // position: relative;

                                transition: 0.2s;

                                cursor: pointer;
                                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

                                .header-dropdown-button-text {
                                    color: #3c373a;
                                    font-family: stolzl, sans-serif;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 14px;

                                    margin-right: 85px;

                                    b {
                                        color: #ffffff;
                                    }
                                }

                                img {
                                    width: 10px;
                                    height: 21px;

                                    transition: 0.2s;
                                }
                            }
                        }
                        .header-panel-bottom {
                            z-index: 99;
                            pointer-events: all;
                            position: absolute;
                            justify-content: center;
                            display: flex;
                            max-width: 214px;
                            width: 100%;

                            .header-dropdown-list {
                                position: absolute;
                                z-index: -99;

                                display: flex;
                                padding-top: 20px;

                                max-width: 216px;
                                width: 100%;

                                background-color: rgba(0, 0, 0, 0.8);
                                border-radius: 0 0 8px 8px;

                                border: 1px solid #e0dee3;

                                transition: 0.25s ease-out;

                                box-sizing: border-box;

                                transform-origin: top;

                                .header-dropdown-list-element {
                                    width: 100%;
                                    height: 14px;

                                    display: flex;
                                    justify-content: center;

                                    cursor: pointer;
                                    transition: 0.2s;

                                    color: #fff;
                                    font-family: Stolzl, sans-serif;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 14px;

                                    margin: 26px 11px;

                                    transform-origin: top;

                                    &:last-child {
                                        .header-dropdown-list-element-inner {
                                            border: 0;
                                        }
                                    }

                                    .header-dropdown-list-element-inner {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;

                                        .header-dropdown-list-element-label {
                                            display: flex;
                                            align-items: center;
                                            cursor: pointer;
                                            margin-bottom: 10px;
                                        }

                                        .header-dropdown-list-element-input-checkbox {
                                            display: none;
                                        }

                                        .custom-checkbox {
                                            width: 16px;
                                            height: 16px;
                                            position: relative;
                                            margin-right: 10px;
                                        }

                                        .custom-checkbox::before,
                                        .custom-checkbox::after {
                                            content: "";
                                            position: absolute;
                                            border-radius: 1px;
                                        }

                                        .custom-checkbox::before {
                                            width: 17px;
                                            height: 17px;
                                            border: 1px solid #fff;
                                        }

                                        .custom-checkbox::after {
                                            top: 0px;
                                            left: 0px;
                                            width: 17px;
                                            height: 17px;
                                            border: 1px solid #fff;
                                        }

                                        .header-dropdown-list-element-input-checkbox:checked
                                            + .custom-checkbox::before {
                                            background-color: #fff;
                                            width: 9px;
                                            height: 9px;
                                            top: 4px;
                                            left: 4px;
                                        }

                                        .header-dropdown-list-element-text {
                                            color: #fff;
                                            font-size: 14px;
                                        }

                                        .header-dropdown-list-checkbox-button {
                                            width: 16px;
                                            height: 16px;

                                            // border: 1px solid rgb(63, 63, 63);
                                            border: 1px solid rgb(255, 255, 255);
                                            border-radius: 1px;
                                            box-sizing: border-box;

                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            .header-dropdown-list-checkbox-button-inner {
                                                width: 10px;
                                                height: 10px;

                                                // background-color: #a15652;
                                                background-color: rgba(
                                                    77,
                                                    172,
                                                    137,
                                                    1
                                                );
                                                border-radius: 1px;

                                                transition: 0.2s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .show-options {
                        position: absolute;
                        top: 7.2vh;
                        right: 10px;
                        .show-option {
                            margin: 10px 0 0 0;
                            .show-option-label {
                                display: flex;

                                .header-switch-element {
                                    margin: 0 12px 4px 28px;
                                }

                                span {
                                    font-size: 12px;
                                    line-height: 14px;
                                    font-weight: 400;
                                    font-family: stolzl, sans-serif;
                                    width: 100px;
                                    height: 14px;
                                    color: #3c373a;
                                }
                            }
                        }
                    }
                }
                .header-panel-mobile-rotated {
                    display: none;
                }
            }
        }
    }
}
@media (max-height: 960px) and (orientation: portrait) {
    .main {
        .header {
            .header-logo-button {
                .header-logo {
                    position: relative;
                    .header-logo-top {
                        margin-top: -53px;
                        margin-left: 10px;
                        img {
                            width: 70px !important;
                            height: auto !important;
                        }
                    }
                }
                .arrow-back-button {
                    border: none;
                    z-index: 99;
                    background-color: #f2f1ed;
                    width: auto;
                    height: auto;
                    border-radius: 40px;
                    padding: 1px 16px;
                    gap: 6px;

                    position: fixed;
                    top: 10vh;
                    left: 15px;

                    cursor: pointer;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        line-height: 14px;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: stolzl, sans-serif;
                        color: #111;
                    }
                }

                .header-container-img {
                    display: block;
                    position: absolute;
                    top: -20px;
                    left: 0;
                }
            }
            .header-filter-elements {
                top: 205px;
                left: 25px;
                position: absolute;
                z-index: 99;
                display: block;
                justify-content: flex-start;
                align-items: center;
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
                .header-filter-section {
                    .header-filter-button {
                        margin: 8px 0;
                    }
                }
            }

            .header-container {
                max-width: 97%;
                width: 100%;

                margin: 0 auto;
                margin-top: 28px;

                display: flex;
                align-items: center;
                justify-content: end;

                .header-panel {
                    display: none;
                }
                .header-panel-mobile {
                    display: block;
                    .header-logo {
                        z-index: 100;
                        .header-logo-top {
                            margin-top: -50px;
                            img {
                                width: 80px !important;
                                height: auto !important;
                            }
                        }
                    }
                    .header-select {
                        display: flex;
                        max-width: 200px;
                        width: 100%;
                        margin-top: 2px;
                        margin-right: 10px;
                        .header-project-type-select {
                            max-width: 200px;
                            width: 100%;
                            z-index: 100;
                            .header-dropdown-button {
                                border: solid 1px #e0dee3;
                                border-radius: 40px;
                                height: 40px;
                                padding: 0 16px;

                                background-color: #fff;

                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                // position: relative;

                                transition: 0.2s;

                                cursor: pointer;
                                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

                                .header-dropdown-button-text {
                                    color: #3c373a;
                                    font-family: stolzl, sans-serif;
                                    font-size: 10px;
                                    font-weight: 400;
                                    line-height: 14px;

                                    margin-right: 80px;

                                    b {
                                        color: #ffffff;
                                    }
                                }

                                img {
                                    width: 10px;
                                    height: 21px;

                                    transition: 0.2s;
                                }
                            }
                        }
                        .header-panel-bottom {
                            z-index: 99;
                            pointer-events: all;
                            position: absolute;
                            justify-content: center;
                            display: flex;
                            max-width: 200px;
                            width: 100%;

                            .header-dropdown-list {
                                position: absolute;
                                z-index: -99;

                                display: flex;
                                padding-top: 20px;

                                max-width: 216px;
                                width: 100%;

                                background-color: rgba(0, 0, 0, 0.8);
                                border-radius: 0 0 8px 8px;

                                border: 1px solid #e0dee3;

                                transition: 0.25s ease-out;

                                box-sizing: border-box;

                                transform-origin: top;

                                .header-dropdown-list-element {
                                    width: 100%;
                                    height: 14px;

                                    display: flex;
                                    justify-content: center;

                                    cursor: pointer;
                                    transition: 0.2s;

                                    color: #fff;
                                    font-family: Stolzl, sans-serif;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 14px;

                                    margin: 26px 11px;

                                    transform-origin: top;

                                    &:last-child {
                                        .header-dropdown-list-element-inner {
                                            border: 0;
                                        }
                                    }

                                    .header-dropdown-list-element-inner {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;

                                        .header-dropdown-list-element-label {
                                            display: flex;
                                            align-items: center;
                                            cursor: pointer;
                                            margin-bottom: 10px;
                                        }

                                        .header-dropdown-list-element-input-checkbox {
                                            display: none;
                                        }

                                        .custom-checkbox {
                                            width: 16px;
                                            height: 16px;
                                            position: relative;
                                            margin-right: 10px;
                                        }

                                        .custom-checkbox::before,
                                        .custom-checkbox::after {
                                            content: "";
                                            position: absolute;
                                            border-radius: 1px;
                                        }

                                        .custom-checkbox::before {
                                            width: 17px;
                                            height: 17px;
                                            border: 1px solid #fff;
                                        }

                                        .custom-checkbox::after {
                                            top: 0px;
                                            left: 0px;
                                            width: 17px;
                                            height: 17px;
                                            border: 1px solid #fff;
                                        }

                                        .header-dropdown-list-element-input-checkbox:checked
                                            + .custom-checkbox::before {
                                            background-color: #fff;
                                            width: 9px;
                                            height: 9px;
                                            top: 4px;
                                            left: 4px;
                                        }

                                        .header-dropdown-list-element-text {
                                            color: #fff;
                                            font-size: 14px;
                                        }

                                        .header-dropdown-list-checkbox-button {
                                            width: 16px;
                                            height: 16px;

                                            // border: 1px solid rgb(63, 63, 63);
                                            border: 1px solid rgb(255, 255, 255);
                                            border-radius: 1px;
                                            box-sizing: border-box;

                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            .header-dropdown-list-checkbox-button-inner {
                                                width: 10px;
                                                height: 10px;

                                                // background-color: #a15652;
                                                background-color: rgba(
                                                    77,
                                                    172,
                                                    137,
                                                    1
                                                );
                                                border-radius: 1px;

                                                transition: 0.2s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .show-options {
                        position: absolute;
                        top: 8.5vh;
                        right: 10px;
                        .show-option {
                            margin: 10px 0 0 0;
                            .show-option-label {
                                display: flex;

                                .header-switch-element {
                                    margin: 0 12px 4px 28px;
                                }

                                span {
                                    font-size: 12px;
                                    line-height: 14px;
                                    font-weight: 400;
                                    font-family: stolzl, sans-serif;
                                    width: 100px;
                                    height: 14px;
                                    color: #3c373a;
                                }
                            }
                        }
                    }
                }
                .header-panel-mobile-rotated {
                    display: none;
                }
            }
        }
    }
}
// @media (max-height: 1200px) and (orientation: portrait) {
//     .main {
//         .header {
//             .header-logo-button {
//                 .header-logo {
//                     position: relative;
//                     .header-logo-top {
//                         margin-top: 0px;
//                         margin-left: -20px;
//                         img {
//                             width: 70px !important;
//                             height: auto !important;
//                         }
//                     }
//                 }
//                 .arrow-back-button {
//                     border: none;
//                     z-index: 99;
//                     background-color: #f2f1ed;
//                     width: auto;
//                     height: auto;
//                     border-radius: 40px;
//                     padding: 1px 16px;
//                     gap: 6px;

//                     position: fixed;
//                     top: 8vh;
//                     left: 17px;

//                     cursor: pointer;

//                     display: flex;
//                     align-items: center;
//                     justify-content: space-between;

//                     p {
//                         line-height: 14px;
//                         font-size: 12px;
//                         font-weight: 400;
//                         font-family: stolzl, sans-serif;
//                         color: #111;
//                     }
//                 }

//                 .header-container-img {
//                     display: block;
//                     position: absolute;
//                     top: -20px;
//                     left: 0;
//                 }
//             }
//             .header-filter-elements {
//                 top: 205px;
//                 left: 25px;
//                 position: absolute;
//                 z-index: 99;
//                 display: block;
//                 justify-content: flex-start;
//                 align-items: center;
//                 opacity: 1;
//                 transition: opacity 0.3s ease-in-out;
//                 .header-filter-section {
//                     .header-filter-button {
//                         margin: 8px 0;
//                     }
//                 }
//             }

//             .header-container {
//                 max-width: 97%;
//                 width: 100%;

//                 margin: 0 auto;
//                 margin-top: 28px;

//                 display: flex;
//                 align-items: center;
//                 justify-content: end;

//                 .header-panel {
//                     display: none;
//                 }
//                 .header-panel-mobile {
//                     display: block;
//                     .header-logo {
//                         z-index: 100;
//                         .header-logo-top {
//                             margin-top: -50px;
//                             img {
//                                 width: 80px !important;
//                                 height: auto !important;
//                             }
//                         }
//                     }
//                     .header-select {
//                         display: flex;
//                         max-width: 200px;
//                         width: 100%;
//                         margin-top: -68px;
//                         margin-right: 10px;
//                         .header-project-type-select {
//                             max-width: 200px;
//                             width: 100%;
//                             z-index: 100;
//                             .header-dropdown-button {
//                                 border: solid 1px #e0dee3;
//                                 border-radius: 40px;
//                                 height: 40px;
//                                 padding: 0 16px;

//                                 background-color: #fff;

//                                 display: flex;
//                                 align-items: center;
//                                 justify-content: space-between;

//                                 // position: relative;

//                                 transition: 0.2s;

//                                 cursor: pointer;
//                                 box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

//                                 .header-dropdown-button-text {
//                                     color: #3c373a;
//                                     font-family: stolzl, sans-serif;
//                                     font-size: 10px;
//                                     font-weight: 400;
//                                     line-height: 14px;

//                                     margin-right: 80px;

//                                     b {
//                                         color: #ffffff;
//                                     }
//                                 }

//                                 img {
//                                     width: 10px;
//                                     height: 21px;

//                                     transition: 0.2s;
//                                 }
//                             }
//                         }
//                         .header-panel-bottom {
//                             z-index: 99;
//                             pointer-events: all;
//                             position: absolute;
//                             justify-content: center;
//                             display: flex;
//                             max-width: 200px;
//                             width: 100%;

//                             .header-dropdown-list {
//                                 position: absolute;
//                                 z-index: -99;

//                                 display: flex;
//                                 padding-top: 20px;

//                                 max-width: 216px;
//                                 width: 100%;

//                                 background-color: rgba(0, 0, 0, 0.8);
//                                 border-radius: 0 0 8px 8px;

//                                 border: 1px solid #e0dee3;

//                                 transition: 0.25s ease-out;

//                                 box-sizing: border-box;

//                                 transform-origin: top;

//                                 .header-dropdown-list-element {
//                                     width: 100%;
//                                     height: 14px;

//                                     display: flex;
//                                     justify-content: center;

//                                     cursor: pointer;
//                                     transition: 0.2s;

//                                     color: #fff;
//                                     font-family: Stolzl, sans-serif;
//                                     font-size: 14px;
//                                     font-weight: 400;
//                                     line-height: 14px;

//                                     margin: 26px 11px;

//                                     transform-origin: top;

//                                     &:last-child {
//                                         .header-dropdown-list-element-inner {
//                                             border: 0;
//                                         }
//                                     }

//                                     .header-dropdown-list-element-inner {
//                                         width: 100%;
//                                         display: flex;
//                                         flex-direction: column;
//                                         align-items: flex-start;

//                                         .header-dropdown-list-element-label {
//                                             display: flex;
//                                             align-items: center;
//                                             cursor: pointer;
//                                             margin-bottom: 10px;
//                                         }

//                                         .header-dropdown-list-element-input-checkbox {
//                                             display: none;
//                                         }

//                                         .custom-checkbox {
//                                             width: 16px;
//                                             height: 16px;
//                                             position: relative;
//                                             margin-right: 10px;
//                                         }

//                                         .custom-checkbox::before,
//                                         .custom-checkbox::after {
//                                             content: "";
//                                             position: absolute;
//                                             border-radius: 1px;
//                                         }

//                                         .custom-checkbox::before {
//                                             width: 17px;
//                                             height: 17px;
//                                             border: 1px solid #fff;
//                                         }

//                                         .custom-checkbox::after {
//                                             top: 0px;
//                                             left: 0px;
//                                             width: 17px;
//                                             height: 17px;
//                                             border: 1px solid #fff;
//                                         }

//                                         .header-dropdown-list-element-input-checkbox:checked
//                                             + .custom-checkbox::before {
//                                             background-color: #fff;
//                                             width: 9px;
//                                             height: 9px;
//                                             top: 4px;
//                                             left: 4px;
//                                         }

//                                         .header-dropdown-list-element-text {
//                                             color: #fff;
//                                             font-size: 14px;
//                                         }

//                                         .header-dropdown-list-checkbox-button {
//                                             width: 16px;
//                                             height: 16px;

//                                             // border: 1px solid rgb(63, 63, 63);
//                                             border: 1px solid rgb(255, 255, 255);
//                                             border-radius: 1px;
//                                             box-sizing: border-box;

//                                             display: flex;
//                                             align-items: center;
//                                             justify-content: center;

//                                             .header-dropdown-list-checkbox-button-inner {
//                                                 width: 10px;
//                                                 height: 10px;

//                                                 // background-color: #a15652;
//                                                 background-color: rgba(
//                                                     77,
//                                                     172,
//                                                     137,
//                                                     1
//                                                 );
//                                                 border-radius: 1px;

//                                                 transition: 0.2s;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     .show-options {
//                         position: absolute;
//                         top: 7vh;
//                         right: 30px;
//                         .show-option {
//                             margin: 10px 0 0 0;
//                             .show-option-label {
//                                 display: flex;

//                                 .header-switch-element {
//                                     margin: 0 12px 4px 28px;
//                                 }

//                                 span {
//                                     font-size: 12px;
//                                     line-height: 14px;
//                                     font-weight: 400;
//                                     font-family: stolzl, sans-serif;
//                                     width: 100px;
//                                     height: 14px;
//                                     color: #3c373a;
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 .header-panel-mobile-rotated {
//                     display: none;
//                 }
//             }
//         }
//     }
// }
@media (max-width: 1100px) and (orientation: landscape) {
    .main {
        .header {
            .header-logo-button {
                .header-logo {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    z-index: 100;
                }
                .arrow-back-button {
                    background-color: #f2f1ed;
                    width: 80px;
                    height: 32px;
                    border-radius: 40px;
                    padding: 8px 16px;
                    gap: 6px;

                    position: fixed;
                    top: 330px;
                    left: 15px;

                    cursor: pointer;

                    display: none;
                    align-items: center;
                    justify-content: space-between;

                    border: 1px solid #999999;

                    p {
                        line-height: 14px;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: stolzl, sans-serif;
                        color: #111;
                    }
                }

                .header-filter-container {
                    display: none;
                }
                .header-container-img {
                    display: none;
                }
            }
            .header-filter-elements {
                display: none;
            }

            .header-container {
                max-width: 97%;
                width: 100%;

                margin: 0 auto;
                margin-top: 28px;

                display: flex;
                align-items: center;
                justify-content: end;

                .header-panel {
                    display: none;
                }
                .header-panel-mobile {
                    display: none;
                }
                .header-panel-mobile-rotated {
                    display: block;
                    // .header-filter-elements {
                    //     display: flex;
                    //     justify-content: space-around;
                    //     align-items: center;
                    //     width: 80%;
                    //     opacity: 1;
                    //     transition: opacity 0.3s ease-in-out;
                    //     .header-filter-section {
                    //         display: flex;
                    //     }
                    // }
                    // .header-filter-button.close-button {
                    //     margin-left: 5%;
                    // }

                    // &.collapsed .header-filter-elements {
                    //     opacity: 0;
                    //     pointer-events: none;
                    // }

                    // .header-filter-button {
                    //     cursor: pointer;
                    //     display: flex;
                    //     justify-content: center;
                    //     align-items: center;
                    //     padding: 10px;
                    //     margin: 0 0.5%;
                    //     img {
                    //         width: 66px;
                    //         height: 66px;
                    //     }
                    // }
                    .header-filter-elements {
                        z-index: 100;
                        position: absolute;
                        left: 100px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 80%;
                        opacity: 1;
                        transition: opacity 0.3s ease-in-out;
                        .header-filter-section {
                            display: flex;
                            .header-filter-button {
                                margin: 0 5px;
                            }
                        }
                    }
                    .header-container-img {
                        position: absolute;
                        top: 0;
                        right: 100px;
                    }
                    .header-select {
                        display: flex;
                        max-width: 214px;
                        width: 100%;
                        .header-project-type-select {
                            z-index: 100;
                            max-width: 214px;
                            width: 100%;
                            .header-dropdown-button {
                                border: solid 1px #e0dee3;
                                border-radius: 40px;
                                height: 40px;
                                padding: 0 16px;

                                background-color: #fff;

                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                position: relative;

                                transition: 0.2s;

                                cursor: pointer;
                                position: relative;
                                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

                                .header-dropdown-button-text {
                                    color: #3c373a;
                                    font-family: stolzl, sans-serif;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 14px;

                                    margin-right: 85px;

                                    b {
                                        color: #ffffff;
                                    }
                                }

                                img {
                                    width: 10px;
                                    height: 21px;

                                    transition: 0.2s;
                                }
                            }
                        }
                        .header-panel-bottom {
                            pointer-events: all;
                            position: absolute;
                            justify-content: center;
                            display: flex;
                            max-width: 214px;
                            width: 100%;
                            z-index: 99;

                            .header-dropdown-list {
                                position: absolute;
                                z-index: -99;

                                display: flex;
                                padding-top: 20px;

                                max-width: 216px;
                                width: 100%;

                                background-color: rgba(0, 0, 0, 0.2);
                                border-radius: 0 0 8px 8px;

                                border: 1px solid #e0dee3;

                                transition: 0.25s ease-out;

                                box-sizing: border-box;

                                transform-origin: top;

                                .header-dropdown-list-element {
                                    width: 100%;
                                    height: 14px;

                                    display: flex;
                                    justify-content: center;

                                    cursor: pointer;
                                    transition: 0.2s;

                                    color: #fff;
                                    font-family: Stolzl, sans-serif;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 14px;

                                    margin: 26px 11px;

                                    transform-origin: top;

                                    &:last-child {
                                        .header-dropdown-list-element-inner {
                                            border: 0;
                                        }
                                    }

                                    .header-dropdown-list-element-inner {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;

                                        .header-dropdown-list-element-label {
                                            display: flex;
                                            align-items: center;
                                            cursor: pointer;
                                            margin-bottom: 10px;
                                        }

                                        .header-dropdown-list-element-input-checkbox {
                                            display: none;
                                        }

                                        .custom-checkbox {
                                            width: 16px;
                                            height: 16px;
                                            position: relative;
                                            margin-right: 10px;
                                        }

                                        .custom-checkbox::before,
                                        .custom-checkbox::after {
                                            content: "";
                                            position: absolute;
                                            border-radius: 1px;
                                        }

                                        .custom-checkbox::before {
                                            width: 17px;
                                            height: 17px;
                                            border: 1px solid #fff;
                                        }

                                        .custom-checkbox::after {
                                            top: 0px;
                                            left: 0px;
                                            width: 17px;
                                            height: 17px;
                                            border: 1px solid #fff;
                                        }

                                        .header-dropdown-list-element-input-checkbox:checked
                                            + .custom-checkbox::before {
                                            background-color: #fff;
                                            width: 9px;
                                            height: 9px;
                                            top: 4px;
                                            left: 4px;
                                        }

                                        .header-dropdown-list-element-text {
                                            color: #fff;
                                            font-size: 14px;
                                        }

                                        .header-dropdown-list-checkbox-button {
                                            width: 16px;
                                            height: 16px;

                                            // border: 1px solid rgb(63, 63, 63);
                                            border: 1px solid rgb(255, 255, 255);
                                            border-radius: 1px;
                                            box-sizing: border-box;

                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            .header-dropdown-list-checkbox-button-inner {
                                                width: 10px;
                                                height: 10px;

                                                // background-color: #a15652;
                                                background-color: rgba(
                                                    77,
                                                    172,
                                                    137,
                                                    1
                                                );
                                                border-radius: 1px;

                                                transition: 0.2s;
                                            }
                                        }
                                    }
                                }
                            }

                            .header-guide {
                                position: fixed;
                                z-index: 1;

                                background: #fff;
                                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

                                // height: 164px;
                                max-width: 377px;
                                width: 100%;

                                transition: 0.25s;
                                transform-origin: top;

                                padding: 24px 0 24px 0;
                                box-sizing: border-box;

                                display: flex;
                                align-items: center;
                                flex-direction: column;

                                .header-guide-element {
                                    max-width: calc(100% - 32px);
                                    width: 100%;

                                    margin-bottom: 20px;

                                    display: flex;
                                    align-items: center;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    .header-guide-element-img {
                                        min-width: 48px;
                                        height: 48px;

                                        background-color: #d1eeea;
                                        border-radius: 150px;

                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        margin-right: 16px;

                                        img {
                                            height: 24px;
                                        }
                                    }

                                    .header-guide-element-label {
                                        color: #1c1e27;
                                        font-family: Graphik Arabic;
                                        font-size: 14px;
                                        font-weight: 400;
                                    }
                                }

                                .header-guide-button {
                                    width: 100%;
                                    max-width: 140px;
                                    height: 38px;
                                    background-color: #00b087;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    cursor: pointer;

                                    transition: 0.2s;

                                    .header-guide-button-text {
                                        color: #ffffff;
                                        font-family: Graphik Arabic;
                                        font-size: 11px;
                                        font-weight: 400;

                                        letter-spacing: 1px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                    .header-bottom {
                        pointer-events: none;
                        .show-options {
                            display: block;
                            position: fixed;
                            top: 85%;
                            .show-option {
                                .show-option-label {
                                    display: flex;

                                    .header-switch-element {
                                        margin: 0 12px 12px 28px;
                                    }

                                    span {
                                        font-size: 12px;
                                        line-height: 14px;
                                        font-weight: 400;
                                        font-family: stolzl, sans-serif;
                                        width: 100px;
                                        height: 14px;
                                        color: #3c373a;
                                    }
                                }
                            }
                        }
                        .arrow-back-button {
                            text-decoration: none;
                            border: none;
                            z-index: 99;
                            background-color: #f2f1ed;
                            width: auto;
                            height: auto;
                            border-radius: 40px;
                            padding: 1px 16px;
                            gap: 6px;

                            position: fixed;
                            top: 85%;
                            left: 15px;

                            cursor: pointer;

                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            p {
                                line-height: 14px;
                                font-size: 12px;
                                font-weight: 400;
                                font-family: stolzl, sans-serif;
                                color: #111;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 940px) and (orientation: landscape) {
    .main {
        .header {
            .header-logo-button {
                .header-logo {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    z-index: 100;
                }
                .arrow-back-button {
                    background-color: #f2f1ed;
                    width: 80px;
                    height: 32px;
                    border-radius: 40px;
                    padding: 8px 16px;
                    gap: 6px;

                    position: fixed;
                    top: 330px;
                    left: 15px;

                    cursor: pointer;

                    display: none;
                    align-items: center;
                    justify-content: space-between;

                    border: 1px solid #999999;

                    p {
                        line-height: 14px;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: stolzl, sans-serif;
                        color: #111;
                    }
                }

                .header-filter-container {
                    display: none;
                }
                .header-container-img {
                    display: none;
                }
            }
            .header-filter-elements {
                display: none;
            }

            .header-container {
                max-width: 97%;
                width: 100%;

                margin: 0 auto;
                margin-top: 28px;

                display: flex;
                align-items: center;
                justify-content: end;

                .header-panel {
                    display: none;
                }
                .header-panel-mobile {
                    display: none;
                }
                .header-panel-mobile-rotated {
                    display: block;
                    // .header-filter-elements {
                    //     display: flex;
                    //     justify-content: space-around;
                    //     align-items: center;
                    //     width: 80%;
                    //     opacity: 1;
                    //     transition: opacity 0.3s ease-in-out;
                    //     .header-filter-section {
                    //         display: flex;
                    //     }
                    // }
                    // .header-filter-button.close-button {
                    //     margin-left: 5%;
                    // }

                    // &.collapsed .header-filter-elements {
                    //     opacity: 0;
                    //     pointer-events: none;
                    // }

                    // .header-filter-button {
                    //     cursor: pointer;
                    //     display: flex;
                    //     justify-content: center;
                    //     align-items: center;
                    //     padding: 10px;
                    //     margin: 0 0.5%;
                    //     img {
                    //         width: 66px;
                    //         height: 66px;
                    //     }
                    // }
                    .header-filter-elements {
                        z-index: 100;
                        position: absolute;
                        left: 30px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        max-width: 80%;
                        opacity: 1;
                        transition: opacity 0.3s ease-in-out;
                        .header-filter-section {
                            display: flex;
                            .header-filter-button {
                                margin: 0 6px;
                                img {
                                    width: 60px;
                                }
                            }
                        }
                    }
                    .header-container-img {
                        position: absolute;
                        top: 0;
                        right: 100px;
                    }
                    .header-select {
                        display: flex;
                        max-width: 200px;
                        width: 100%;
                        .header-project-type-select {
                            z-index: 100;
                            max-width: 200px;
                            width: 100%;
                            .header-dropdown-button {
                                border: solid 1px #e0dee3;
                                border-radius: 40px;
                                height: 40px;
                                padding: 0 16px;

                                background-color: #fff;

                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                position: relative;

                                transition: 0.2s;

                                cursor: pointer;
                                position: relative;
                                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

                                .header-dropdown-button-text {
                                    color: #3c373a;
                                    font-family: stolzl, sans-serif;
                                    font-size: 10px;
                                    font-weight: 400;
                                    line-height: 14px;

                                    margin-right: 80px;

                                    b {
                                        color: #ffffff;
                                    }
                                }

                                img {
                                    width: 10px;
                                    height: 21px;

                                    transition: 0.2s;
                                }
                            }
                        }
                        .header-panel-bottom {
                            pointer-events: all;
                            position: absolute;
                            justify-content: center;
                            display: flex;
                            max-width: 200px;
                            width: 100%;
                            z-index: 99;

                            .header-dropdown-list {
                                position: absolute;
                                z-index: -99;

                                display: flex;
                                padding-top: 20px;

                                max-width: 200px;
                                width: 100%;

                                background-color: rgba(0, 0, 0, 0.2);
                                border-radius: 0 0 8px 8px;

                                border: 1px solid #e0dee3;

                                transition: 0.25s ease-out;

                                box-sizing: border-box;

                                transform-origin: top;

                                .header-dropdown-list-element {
                                    width: 100%;
                                    height: 14px;

                                    display: flex;
                                    justify-content: center;

                                    cursor: pointer;
                                    transition: 0.2s;

                                    color: #fff;
                                    font-family: Stolzl, sans-serif;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 14px;

                                    margin: 26px 11px;

                                    transform-origin: top;

                                    &:last-child {
                                        .header-dropdown-list-element-inner {
                                            border: 0;
                                        }
                                    }

                                    .header-dropdown-list-element-inner {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;

                                        .header-dropdown-list-element-label {
                                            display: flex;
                                            align-items: center;
                                            cursor: pointer;
                                            margin-bottom: 10px;
                                        }

                                        .header-dropdown-list-element-input-checkbox {
                                            display: none;
                                        }

                                        .custom-checkbox {
                                            width: 16px;
                                            height: 16px;
                                            position: relative;
                                            margin-right: 10px;
                                        }

                                        .custom-checkbox::before,
                                        .custom-checkbox::after {
                                            content: "";
                                            position: absolute;
                                            border-radius: 1px;
                                        }

                                        .custom-checkbox::before {
                                            width: 17px;
                                            height: 17px;
                                            border: 1px solid #fff;
                                        }

                                        .custom-checkbox::after {
                                            top: 0px;
                                            left: 0px;
                                            width: 17px;
                                            height: 17px;
                                            border: 1px solid #fff;
                                        }

                                        .header-dropdown-list-element-input-checkbox:checked
                                            + .custom-checkbox::before {
                                            background-color: #fff;
                                            width: 9px;
                                            height: 9px;
                                            top: 4px;
                                            left: 4px;
                                        }

                                        .header-dropdown-list-element-text {
                                            color: #fff;
                                            font-size: 14px;
                                        }

                                        .header-dropdown-list-checkbox-button {
                                            width: 16px;
                                            height: 16px;

                                            // border: 1px solid rgb(63, 63, 63);
                                            border: 1px solid rgb(255, 255, 255);
                                            border-radius: 1px;
                                            box-sizing: border-box;

                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            .header-dropdown-list-checkbox-button-inner {
                                                width: 10px;
                                                height: 10px;

                                                // background-color: #a15652;
                                                background-color: rgba(
                                                    77,
                                                    172,
                                                    137,
                                                    1
                                                );
                                                border-radius: 1px;

                                                transition: 0.2s;
                                            }
                                        }
                                    }
                                }
                            }

                            .header-guide {
                                position: fixed;
                                z-index: 1;

                                background: #fff;
                                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

                                // height: 164px;
                                max-width: 377px;
                                width: 100%;

                                transition: 0.25s;
                                transform-origin: top;

                                padding: 24px 0 24px 0;
                                box-sizing: border-box;

                                display: flex;
                                align-items: center;
                                flex-direction: column;

                                .header-guide-element {
                                    max-width: calc(100% - 32px);
                                    width: 100%;

                                    margin-bottom: 20px;

                                    display: flex;
                                    align-items: center;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    .header-guide-element-img {
                                        min-width: 48px;
                                        height: 48px;

                                        background-color: #d1eeea;
                                        border-radius: 150px;

                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        margin-right: 16px;

                                        img {
                                            height: 24px;
                                        }
                                    }

                                    .header-guide-element-label {
                                        color: #1c1e27;
                                        font-family: Graphik Arabic;
                                        font-size: 14px;
                                        font-weight: 400;
                                    }
                                }

                                .header-guide-button {
                                    width: 100%;
                                    max-width: 140px;
                                    height: 38px;
                                    background-color: #00b087;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    cursor: pointer;

                                    transition: 0.2s;

                                    .header-guide-button-text {
                                        color: #ffffff;
                                        font-family: Graphik Arabic;
                                        font-size: 11px;
                                        font-weight: 400;

                                        letter-spacing: 1px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                    .header-bottom {
                        pointer-events: none;
                        .show-options {
                            display: block;
                            position: fixed;
                            top: 85%;
                            .show-option {
                                .show-option-label {
                                    display: flex;

                                    .header-switch-element {
                                        margin: 0 12px 12px 28px;
                                    }

                                    span {
                                        font-size: 12px;
                                        line-height: 14px;
                                        font-weight: 400;
                                        font-family: stolzl, sans-serif;
                                        width: 100px;
                                        height: 14px;
                                        color: #3c373a;
                                    }
                                }
                            }
                        }
                        .arrow-back-button {
                            text-decoration: none;
                            border: none;
                            z-index: 99;
                            background-color: #f2f1ed;
                            width: auto;
                            height: auto;
                            border-radius: 40px;
                            padding: 1px 16px;
                            gap: 6px;

                            position: fixed;
                            top: 85%;
                            left: 15px;

                            cursor: pointer;

                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            p {
                                line-height: 14px;
                                font-size: 12px;
                                font-weight: 400;
                                font-family: stolzl, sans-serif;
                                color: #111;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 750px) and (orientation: landscape) {
    .main {
        .header {
            .header-logo-button {
                .header-logo {
                    position: absolute;
                    top: 1px;
                    left: 10px;
                    z-index: 100;
                    .header-logo-top {
                        img {
                            width: 80px;
                        }
                    }
                }
                .arrow-back-button {
                    background-color: #f2f1ed;
                    width: 80px;
                    height: 32px;
                    border-radius: 40px;
                    padding: 8px 16px;
                    gap: 6px;

                    position: fixed;
                    top: 330px;
                    left: 15px;

                    cursor: pointer;

                    display: none;
                    align-items: center;
                    justify-content: space-between;

                    border: 1px solid #999999;

                    p {
                        line-height: 14px;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: stolzl, sans-serif;
                        color: #111;
                    }
                }

                .header-filter-container {
                    display: none;
                }
                .header-container-img {
                    display: none;
                }
            }
            .header-filter-elements {
                display: none;
            }

            .header-container {
                max-width: 97%;
                width: 100%;

                margin: 0 auto;
                margin-top: 28px;

                display: flex;
                align-items: center;
                justify-content: end;

                .header-panel {
                    display: none;
                }
                .header-panel-mobile {
                    display: none;
                }
                .header-panel-mobile-rotated {
                    display: block;
                    // .header-filter-elements {
                    //     display: flex;
                    //     justify-content: space-around;
                    //     align-items: center;
                    //     width: 80%;
                    //     opacity: 1;
                    //     transition: opacity 0.3s ease-in-out;
                    //     .header-filter-section {
                    //         display: flex;
                    //     }
                    // }
                    // .header-filter-button.close-button {
                    //     margin-left: 5%;
                    // }

                    // &.collapsed .header-filter-elements {
                    //     opacity: 0;
                    //     pointer-events: none;
                    // }

                    // .header-filter-button {
                    //     cursor: pointer;
                    //     display: flex;
                    //     justify-content: center;
                    //     align-items: center;
                    //     padding: 10px;
                    //     margin: 0 0.5%;
                    //     img {
                    //         width: 66px;
                    //         height: 66px;
                    //     }
                    // }
                    .header-filter-elements {
                        z-index: 100;
                        position: absolute;
                        left: 30px;
                        display: flex;
                        top: 20px;
                        justify-content: space-around;
                        align-items: center;
                        max-width: 80%;
                        opacity: 1;
                        transition: opacity 0.3s ease-in-out;
                        .header-filter-section {
                            display: flex;
                            .header-filter-button {
                                margin: 0 6px;
                                img {
                                    width: 50px;
                                }
                            }
                        }
                    }
                    .header-container-img {
                        position: absolute;
                        top: 0;
                        right: 100px;
                    }
                    .header-select {
                        display: flex;
                        max-width: 150px;
                        width: 100%;
                        margin-top: -8px;
                        .header-project-type-select {
                            z-index: 100;
                            max-width: 150px;
                            width: 100%;
                            .header-dropdown-button {
                                border: solid 1px #e0dee3;
                                border-radius: 40px;
                                height: 40px;
                                padding: 0 16px;

                                background-color: #fff;

                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                position: relative;

                                transition: 0.2s;

                                cursor: pointer;
                                position: relative;
                                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

                                .header-dropdown-button-text {
                                    color: #3c373a;
                                    font-family: stolzl, sans-serif;
                                    font-size: 10px;
                                    font-weight: 400;
                                    line-height: 14px;

                                    margin-right: 60px;

                                    b {
                                        color: #ffffff;
                                    }
                                }

                                img {
                                    width: 10px;
                                    height: 21px;

                                    transition: 0.2s;
                                }
                            }
                        }
                        .header-panel-bottom {
                            pointer-events: all;
                            position: absolute;
                            justify-content: center;
                            display: flex;
                            max-width: 148px;
                            width: 100%;
                            z-index: 99;

                            .header-dropdown-list {
                                position: absolute;
                                z-index: -99;

                                display: flex;
                                padding-top: 20px;

                                max-width: 216px;
                                width: 100%;

                                background-color: rgba(0, 0, 0, 0.2);
                                border-radius: 0 0 8px 8px;

                                border: 1px solid #e0dee3;

                                transition: 0.25s ease-out;

                                box-sizing: border-box;

                                transform-origin: top;

                                .header-dropdown-list-element {
                                    width: 100%;
                                    height: 14px;

                                    display: flex;
                                    justify-content: center;

                                    cursor: pointer;
                                    transition: 0.2s;

                                    color: #fff;
                                    font-family: Stolzl, sans-serif;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 14px;

                                    margin: 26px 11px;

                                    transform-origin: top;

                                    &:last-child {
                                        .header-dropdown-list-element-inner {
                                            border: 0;
                                        }
                                    }

                                    .header-dropdown-list-element-inner {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;

                                        .header-dropdown-list-element-label {
                                            display: flex;
                                            align-items: center;
                                            cursor: pointer;
                                            margin-bottom: 10px;
                                        }

                                        .header-dropdown-list-element-input-checkbox {
                                            display: none;
                                        }

                                        .custom-checkbox {
                                            width: 16px;
                                            height: 16px;
                                            position: relative;
                                            margin-right: 10px;
                                        }

                                        .custom-checkbox::before,
                                        .custom-checkbox::after {
                                            content: "";
                                            position: absolute;
                                            border-radius: 1px;
                                        }

                                        .custom-checkbox::before {
                                            width: 17px;
                                            height: 17px;
                                            border: 1px solid #fff;
                                        }

                                        .custom-checkbox::after {
                                            top: 0px;
                                            left: 0px;
                                            width: 17px;
                                            height: 17px;
                                            border: 1px solid #fff;
                                        }

                                        .header-dropdown-list-element-input-checkbox:checked
                                            + .custom-checkbox::before {
                                            background-color: #fff;
                                            width: 9px;
                                            height: 9px;
                                            top: 4px;
                                            left: 4px;
                                        }

                                        .header-dropdown-list-element-text {
                                            color: #fff;
                                            font-size: 14px;
                                        }

                                        .header-dropdown-list-checkbox-button {
                                            width: 16px;
                                            height: 16px;

                                            // border: 1px solid rgb(63, 63, 63);
                                            border: 1px solid rgb(255, 255, 255);
                                            border-radius: 1px;
                                            box-sizing: border-box;

                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            .header-dropdown-list-checkbox-button-inner {
                                                width: 10px;
                                                height: 10px;

                                                // background-color: #a15652;
                                                background-color: rgba(
                                                    77,
                                                    172,
                                                    137,
                                                    1
                                                );
                                                border-radius: 1px;

                                                transition: 0.2s;
                                            }
                                        }
                                    }
                                }
                            }

                            .header-guide {
                                position: fixed;
                                z-index: 1;

                                background: #fff;
                                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

                                // height: 164px;
                                max-width: 377px;
                                width: 100%;

                                transition: 0.25s;
                                transform-origin: top;

                                padding: 24px 0 24px 0;
                                box-sizing: border-box;

                                display: flex;
                                align-items: center;
                                flex-direction: column;

                                .header-guide-element {
                                    max-width: calc(100% - 32px);
                                    width: 100%;

                                    margin-bottom: 20px;

                                    display: flex;
                                    align-items: center;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    .header-guide-element-img {
                                        min-width: 48px;
                                        height: 48px;

                                        background-color: #d1eeea;
                                        border-radius: 150px;

                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        margin-right: 16px;

                                        img {
                                            height: 24px;
                                        }
                                    }

                                    .header-guide-element-label {
                                        color: #1c1e27;
                                        font-family: Graphik Arabic;
                                        font-size: 14px;
                                        font-weight: 400;
                                    }
                                }

                                .header-guide-button {
                                    width: 100%;
                                    max-width: 140px;
                                    height: 38px;
                                    background-color: #00b087;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    cursor: pointer;

                                    transition: 0.2s;

                                    .header-guide-button-text {
                                        color: #ffffff;
                                        font-family: Graphik Arabic;
                                        font-size: 11px;
                                        font-weight: 400;

                                        letter-spacing: 1px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                    .header-bottom {
                        pointer-events: none;
                        .show-options {
                            display: block;
                            position: fixed;
                            top: 85%;
                            .show-option {
                                .show-option-label {
                                    display: flex;

                                    .header-switch-element {
                                        margin: 0 12px 12px 28px;
                                    }

                                    span {
                                        font-size: 12px;
                                        line-height: 14px;
                                        font-weight: 400;
                                        font-family: stolzl, sans-serif;
                                        width: 100px;
                                        height: 14px;
                                        color: #3c373a;
                                    }
                                }
                            }
                        }
                        .arrow-back-button {
                            text-decoration: none;
                            border: none;
                            z-index: 99;
                            background-color: #f2f1ed;
                            width: auto;
                            height: auto;
                            border-radius: 40px;
                            padding: 1px 16px;
                            gap: 6px;

                            position: fixed;
                            top: 85%;
                            left: 15px;

                            cursor: pointer;

                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            p {
                                line-height: 14px;
                                font-size: 12px;
                                font-weight: 400;
                                font-family: stolzl, sans-serif;
                                color: #111;
                            }
                        }
                    }
                }
            }
        }
    }
}
