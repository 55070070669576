.main {
    .guide {
        width: 100vw;
        height: 100vh;

        z-index: 4;
        position: fixed;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: 0.2s;

        .guide-background {
            width: 100vw;
            height: 100vh;

            background: rgba(0, 0, 0, 0.5);

            position: fixed;
        }

        .guide-container {
            position: fixed;

            z-index: 2;
            width: 620px;
            max-width: 91.8%;
            height: 390px;

            background-color: #ffffff;
            border-radius: 20px;

            display: flex;
            flex-direction: column;
            align-items: center;

            transition: 0.2s;

            .guide-title {
                width: 100%;

                margin-top: 48px;

                color: #000;
                font-family: Stolzl;
                font-size: 20px;
                font-weight: 600;

                line-height: 16px;

                text-align: center;
            }

            .guide-desc {
                margin-top: 20px;

                width: 100%;

                color: #1c1e27;

                font-family: Stolzl;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                text-align: center;

                line-height: 16px;
            }

            .guide-tips {
                margin-top: 40px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                gap: 42px;

                .guide-tip {
                    width: 100%;
                    max-width: 140px;
                    height: 120px;

                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .guide-tip-image {
                        width: 100%;
                        max-width: 48px;
                        height: 48px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        // background-color: #D1EEEA;
                        border-radius: 150px;

                        img {
                            width: 38px;
                        }
                    }

                    .guide-tip-label {
                        color: #1c1e27;

                        text-align: center;
                        font-family: Stolzl;
                        font-size: 12px;

                        font-weight: 400;
                        line-height: 20px;

                        margin-top: 15px;
                    }
                }
            }

            .guide-buttons {
                margin-top: 32px;

                display: flex;
                align-items: center;

                gap: 35px;

                .guide-button {
                    width: 200px;
                    height: 46px;

                    background-color: #3c373a;
                    border-radius: 8px;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    padding: 20px;
                    box-sizing: border-box;

                    cursor: pointer;

                    transition: 0.3s;

                    .guide-button-text {
                        color: #ffffff;
                        font-family: Stolzl;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 14px;

                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                }

                .guide-button-transparent {
                    border: solid #000000 1px;
                    box-sizing: border-box;

                    background-color: white;

                    justify-content: center;

                    .guide-button-text {
                        color: #000000;
                    }
                }
            }
        }
    }

    .guide-mobile {
        width: 100vw;
        height: 100vh;

        z-index: 4;
        position: fixed;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: 0.2s;

        .guide-background {
            width: 100vw;
            height: 100vh;

            background: rgba(0, 0, 0, 0.5);

            position: fixed;
        }

        .guide-container {
            position: fixed;

            z-index: 2;
            width: 577px;
            max-width: 91.8%;
            // height: 610px;

            background-color: #ffffff;

            display: flex;
            justify-content: center;

            transition: 0.2s;

            .guide-container-inner {
                width: 330px;
                max-width: 91.1%;
                // height: 100%;

                display: flex;
                align-items: center;
                flex-direction: column;

                padding: 20px 0 20px 0;
                box-sizing: border-box;

                .guide-title {
                    width: 100%;

                    margin-top: 28px;

                    color: #000000;
                    font-family: Stolzl;
                    font-size: 20px;
                    font-weight: 600;

                    line-height: 16px;

                    text-align: center;
                }

                .guide-desc {
                    margin-top: 24px;

                    width: 100%;

                    color: #1c1e26;

                    font-family: Stolzl;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    text-align: center;

                    line-height: 16px;
                }

                .guide-tips {
                    margin-top: 40px;

                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .guide-tip {
                        width: 100%;
                        max-width: 136px;

                        display: flex;
                        align-items: center;
                        // justify-content: space-between;
                        flex-direction: column;
                        gap: 20px;

                        .guide-tip-image {
                            width: 48px;
                            height: 48px;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            background-color: #e1e1e1;
                            border-radius: 150px;
                        }

                        .guide-tip-label {
                            color: #1c1e27;

                            text-align: center;
                            font-family: Stolzl;
                            font-size: 16px;

                            font-weight: 400;
                            line-height: 20px;
                        }
                    }
                }

                .guide-buttons {
                    margin-top: 48px;
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .guide-button {
                        width: 100%;
                        height: 56px;

                        background-color: #3c373a;
                        border-radius: 8px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        cursor: pointer;

                        transition: 0.2s;

                        .guide-button-text {
                            color: #ffffff;
                            font-family: Stolzl;
                            font-size: 12px;
                            font-weight: 500;

                            letter-spacing: 1px;
                            text-transform: uppercase;
                        }
                    }

                    .guide-button-transparent {
                        border: solid #000000 1px;
                        box-sizing: border-box;

                        background-color: white;

                        .guide-button-text {
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
}
