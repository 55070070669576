.half-screen {
    width: 100%;
    height: 100%;

    display: flex;

    .half-screen-element {
        width: 100%;
        height: 100%;
    }
}
