@media (max-width: 4096px) {
    .main {
        .card {
            width: 100vw;
            height: 100vh;

            position: absolute;
            z-index: 9;
            pointer-events: none;

            display: flex;
            align-items: center;

            .card-container {
                position: fixed;
                right: 1.5%;

                max-width: 320px;
                width: 100%;
                max-height: 412px;
                height: 100%;

                border-radius: 0 20px 20px 20px;

                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

                transition: 0.2s;

                background-color: #fff;

                .card-exit {
                    position: fixed;

                    right: 20px;
                    top: 20px;

                    cursor: pointer;

                    img {
                        width: 17px;

                        transition: 0.2s;
                    }
                }

                .card-top {
                    width: 320px;
                    height: 146px;
                    border-radius: 2px 20px 0 60px;

                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: flex-end;

                    background-color: #2c4c53;
                    transition: 0.2s;

                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;

                    .card-top-back {
                        width: 100%;
                        height: 100%;

                        cursor: nesw-resize;
                    }
                }

                .card-bottom {
                    max-width: 87.5%;
                    width: 100%;

                    margin: 0 auto;
                    margin-top: 15px;
                    margin-bottom: 20px;

                    .card-title {
                        max-width: calc(100% - 6.25% - 60px);
                        margin-bottom: 12px;

                        display: inline-flex;

                        text-decoration: none;
                        color: #3c373a;

                        font-family: Stolzl, sans-serif;
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 30px;
                        max-width: 100% !important;

                    }

                    .card-desc {
                        font-family: Stolzl, sans-serif;
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 16px;
                    }

                    .card-bottom-buttons {
                        max-width: 100%;
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .card-bottom-button {
                        width: 100%;
                        height: 38px;

                        margin-top: 15px;

                        border: 1px solid #727171;
                        border-radius: 8px;
                        gap: 12px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        cursor: pointer;
                        transition: 0.2s;

                        .card-bottom-button-inner {
                            width: 100%;
                            max-width: calc(100% - 30px);

                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            color: #3c373a;
                            font-family: Stolzl, sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            text-transform: uppercase;

                            user-select: none;

                            img {
                                width: 11px;

                                transition: 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 6000px) and (orientation: portrait) {
    .main {
        .card {
            width: 100vw;

            position: absolute;
            z-index: 9;
            pointer-events: none;

            display: flex;
            align-items: center;

            .card-container {
                position: fixed;
                right: 1.5%;

                width: 320px;
                height: auto;

                border-radius: 0 20px 20px 20px;

                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

                transition: 0.2s;

                background-color: #fff;

                .card-exit {
                    position: fixed;

                    right: 20px;
                    top: 20px;

                    cursor: pointer;

                    img {
                        width: 17px;

                        transition: 0.2s;
                    }
                }

                .card-top {
                    width: 320px;
                    height: 146px;
                    border-radius: 2px 20px 0 60px;

                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: flex-end;

                    background-color: #2c4c53;
                    transition: 0.2s;

                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;

                    .card-top-back {
                        width: 100%;
                        height: 100%;

                        cursor: nesw-resize;
                    }
                }

                .card-bottom {
                    max-width: 87.5%;
                    width: 100%;

                    margin: 0 auto;
                    margin-top: 15px;
                    margin-bottom: 20px;

                    .card-title {
                        max-width: calc(100% - 6.25% - 60px);
                        margin-bottom: 12px;

                        display: inline-flex;

                        text-decoration: none;
                        color: #3c373a;

                        font-family: Stolzl, sans-serif;
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 30px;
                    }

                    .card-desc {
                        font-family: Stolzl, sans-serif;
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 16px;
                    }

                    .card-bottom-buttons {
                        max-width: 100%;
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .card-bottom-button {
                        width: 100%;
                        height: 38px;

                        margin-top: 15px;

                        border: 1px solid #727171;
                        border-radius: 8px;
                        gap: 12px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        cursor: pointer;
                        transition: 0.2s;

                        .card-bottom-button-inner {
                            width: 100%;
                            max-width: calc(100% - 30px);

                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            color: #3c373a;
                            font-family: Stolzl, sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            text-transform: uppercase;

                            user-select: none;

                            img {
                                width: 11px;

                                transition: 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 10000px) and (orientation: landscape) {
    .main {
        .card {
            width: 100vw;

            position: absolute;
            z-index: 9;
            pointer-events: none;

            display: flex;
            align-items: center;

            .card-container {
                position: fixed;
                right: 1.5%;

                width: 320px;
                height: auto;

                border-radius: 0 20px 20px 20px;

                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

                transition: 0.2s;

                background-color: #fff;

                .card-exit {
                    position: fixed;

                    right: 20px;
                    top: 20px;

                    cursor: pointer;

                    img {
                        width: 17px;

                        transition: 0.2s;
                    }
                }

                .card-top {
                    width: 320px;
                    height: 146px;
                    border-radius: 2px 20px 0 60px;

                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: flex-end;

                    background-color: #2c4c53;
                    transition: 0.2s;

                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;

                    .card-top-back {
                        width: 100%;
                        height: 100%;

                        cursor: nesw-resize;
                    }
                }

                .card-bottom {
                    max-width: 87.5%;
                    width: 100%;

                    margin: 0 auto;
                    margin-top: 15px;
                    margin-bottom: 20px;

                    .card-title {
                        max-width: calc(100% - 6.25% - 60px);
                        margin-bottom: 12px;

                        display: inline-flex;

                        text-decoration: none;
                        color: #3c373a;

                        font-family: Stolzl, sans-serif;
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 30px;
                    }

                    .card-desc {
                        font-family: Stolzl, sans-serif;
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 16px;

                        height: 60px;

                        overflow-y: auto;
                    }

                    .card-bottom-buttons {
                        max-width: 100%;
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .card-bottom-button {
                        width: 100%;
                        height: 38px;

                        margin-top: 15px;

                        border: 1px solid #727171;
                        border-radius: 8px;
                        gap: 12px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        cursor: pointer;
                        transition: 0.2s;

                        .card-bottom-button-inner {
                            width: 100%;
                            max-width: calc(100% - 30px);

                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            color: #3c373a;
                            font-family: Stolzl, sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            text-transform: uppercase;

                            user-select: none;

                            img {
                                width: 11px;

                                transition: 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
}