.start-city-selection {
    width: 100%;
    height: 100%;

    background-color: #525252;

    .logo {
        position: absolute;
        z-index: 3;

        top: 15px;
        left: 50%;
        transform: translateX(-50%);

        img {
            width: 100%;
            height: 100%;
        }
    }

    .start-city-selection-separator {
        position: absolute;
        left: 50%;
        top: calc(50% + 25px);
        transform: translate(-50%, -50%);

        height: 65vh;
        width: 5px;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .start-city-selection-buttons {
        width: 100%;
        height: 100%;

        position: relative;

        .start-city-selection-button {
            width: 100%;
            height: 100%;

            background-size: cover;
            background-position: center;

            box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.6);

            text-decoration: none;
            color: #ffffff;
            font-family: Stolzl, sans-serif;
            font-size: 40px;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.65);
                transition: 0.2s ease-in-out;
            }

            .start-city-selection-button-name {
                padding-top: 8px;
            }
        }
    }
}
