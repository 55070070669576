.main {
    .small-card {
        position: absolute;
        z-index: 30;

        width: 100vw;
        height: 100vh;

        pointer-events: none;

        overflow: hidden;

        .small-card-container {
            position: relative;

            width: 253px;
            max-height: 340px;
            // min-height: 310px;
            height: auto;

            padding-bottom: 5px;

            border-radius: 0 20px 20px 20px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

            background-color: #fff;

            align-items: center;
            justify-content: space-between;

            transition: 0.2s;
            

            .small-card-img {
                width: 100%;
                height: 146px;
                border-radius: 2px 20px 0 60px;

                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: flex-end;

                background-color: #2c4c53;
                transition: 0.2s;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }

            .small-card-exit {
                position: absolute;

                top: 10px;
                right: 10px;

                width: 20px;
                height: 20px;

                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;

                transition: 0.1s;

                img {
                    width: 24px;
                }
            }

            .small-card-preview {
                max-width: 145px;
                width: 100%;
                height: 100%;

                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }

            .small-card-elements {
                max-width: 229px;
                width: 100%;
                max-height: 229px;

                .small-card-elements-title {
                    font-family: Stolzl, sans-serif;
                    font-size: 22px;
                    line-height: 30px;
                    font-weight: 500;
                    color: #3c373a;

                    margin: 10px 0 4px 16px;
                }

                .small-card-elements-desc {
                    font-family: Stolzl, sans-serif;
                    font-size: 11px;
                    font-weight: 300;
                    color: #3c373a;

                    line-height: 16px;

                    margin: 0px 0 8px 16px;

                    height: 64px;
                    overflow-y: auto;
                }

                .small-card-elements-button {
                    max-width: 134px;
                    width: 100%;
                    height: 32px;

                    box-sizing: border-box;
                    border: 1px solid #727171;
                    border-radius: 8px;

                    text-decoration: none;
                    color: #1f4546;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    transition: 0.2s;

                    margin: 8px 103px 9px 16px;

                    .small-card-elements-button-inner {
                        max-width: 107px;
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .small-card-elements-button-label {
                            font-family: Graphik Arabic;
                            font-size: 12px;
                            font-weight: 600;
                        }

                        .small-card-elements-button-arrow {
                            img {
                                transition: 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
}
